import qs from 'qs';

import { ConfigState } from 'src/store/config';
import { FormValues } from './types';

export function queryString2FormValues(queryString: string, config: ConfigState) {
  const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true }) as any;
  const nextFormValues: FormValues = {
    query: parsedParams.query || '',
    filters: {
      person: parsedParams.filters?.person
        ? (getFormValueByID('people', parsedParams.filters.person) as FormValues['filters']['person'])
        : null,
    },
    page: parsedParams.page ? parseInt(parsedParams.page) : 1,
  };

  return nextFormValues;

  function getFormValueByID<T extends Exclude<keyof typeof config, 'texts'>>(key: T, id: string) {
    const data = Object.values(config[key]).find((item: any) => item.id === id);

    if (!data) {
      return null;
    }

    switch (key) {
      case 'people': {
        const person = data as (typeof config.people)[0];
        return {
          value: person.id,
          title: `${person.b} ${person.c}${person.displayCityInSearch ? `(${person.f})` : ''}`,
        };
      }
      default:
        return null;
    }
  }
}

export function formValues2QueryData(values: FormValues) {
  return {
    query: values.query || undefined,
    filters: {
      person: values.filters.person?.value,
    },
    page: values.page,
  };
}
