import qs from 'qs';
import fetch from 'isomorphic-fetch';

import { API_HOST } from 'src/utils/api';
import {
  ROUTE as PAGINATE_ROUTE,
  Params as PaginateParams,
  Result as PaginateResult,
} from '@api/routes/public/submissions/paginate/params';
import { ROUTE as GET_ROUTE, Result as GetResult } from '@api/routes/public/submissions/get/params';
import { QS_STRINGIFY_OPTIONS } from '@api/constants/other';
import { ApiResponse } from '@api/types';
import api from '.';

export const submissions = api.injectEndpoints({
  endpoints: (builder) => ({
    paginateSubmissions: builder.query<ApiResponse<PaginateResult>, PaginateParams>({
      queryFn: async (params) => {
        const response = await fetch(
          `${API_HOST}${PAGINATE_ROUTE}?${qs.stringify(
            {
              ...params,
              query: encodeURIComponent(params.query || ''),
            } satisfies PaginateParams,
            QS_STRINGIFY_OPTIONS,
          )}`,
        );
        const result = (await response.json()) as ApiResponse<PaginateResult>;

        return { data: result };
      },
      providesTags: (result) =>
        result?.success
          ? [
              ...result.data.items.map(({ _id }) => ({ type: 'SUBMISSION', _id } as const)),
              { type: 'SUBMISSION', id: 'ALL' },
            ]
          : [{ type: 'SUBMISSION', id: 'ALL' }],
    }),

    getSubmission: builder.query<ApiResponse<GetResult>, { storyData_id: string; search: string }>({
      queryFn: async ({ storyData_id, search = '' }) => {
        const response = await fetch(`${API_HOST}${GET_ROUTE.replace(':id', storyData_id)}${search}`);
        const result = (await response.json()) as ApiResponse<GetResult>;

        return { data: result };
      },
      providesTags: (result, error, params) => [
        { type: 'SUBMISSION', _id: params.storyData_id },
        { type: 'SUBMISSION', id: 'ALL' },
      ],
    }),
  }),
  overrideExisting: false,
});

export { ApiResponse, PaginateParams, PaginateResult };
