import React from 'react';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';

import css from './Loader.module.scss';

export const LoaderItem = () => {
  return (
    <div className={css.loaderItem}>
      <ContentLoader
        speed={2}
        width={359}
        height={333}
        // animate={false}
        viewBox="0 0 359 333"
        backgroundColor="#0E151D"
        foregroundColor="#080d11"
      >
        <rect x="0" y="15" rx="0" ry="0" width="100%" height="207" />
        <rect x="41" y="247" rx="0" ry="0" width="90%" height="15" />
        <rect x="110" y="279" rx="0" ry="0" width="70%" height="15" />
        <rect x="81" y="310" rx="0" ry="0" width="80%" height="15" />
      </ContentLoader>
    </div>
  );
};

type Props = {
  className?: string;
};

export const LoaderSearchInfoBlock: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={cn(css.loader, className)}>
      <ContentLoader
        speed={2}
        width={265}
        height={35}
        viewBox="0 0 265 35"
        backgroundColor="#FFF"
        foregroundColor="#dceaff"
      >
        <rect x="0" y="0" rx="10" ry="10" width="60" height="35" />
        <rect x="65" y="0" rx="10" ry="10" width="200" height="35" />
      </ContentLoader>
    </div>
  );
};
