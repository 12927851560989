import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';

import { ROOT } from 'src/constants/routes';
import PageLayout from 'src/components/common/PageLayout';
import Main from 'src/components/pages/Main';

export const Routes = (
  <>
    <Route path={ROOT} element={<PageLayout />}>
      <Route path={ROOT} element={<Main />} />
    </Route>
  </>
);

export const routes = createRoutesFromElements(Routes);
export default routes;
