import React from 'react';
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import { AutocompleteChangeReason, InputAdornment } from '@mui/material';
import cn from 'classnames';

import { analytics } from 'src/utils/analytics';

import Flex from 'src/components/common/Flex';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import Autocomplete from 'src/components/common/Autocomplete';

import { useSearch } from './Context';

const Container = styled(
  (props: React.PropsWithChildren & { className?: string }) => (
    <Flex {...props} justifyContent="space-between" alignItems="center">
      {props.children}
    </Flex>
  ),
  { label: 'SearchContainer' },
)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  color: '#04476D',
}));

const SearchButton = styled('button', { label: 'SearchButton' })({
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  marginLeft: '1px',
  padding: '0',
  border: 'none',
  borderRadius: '50%',
  color: '#7D8D96',
  backgroundColor: 'transparent',

  '& img': {
    position: 'relative',
    display: 'block',
    width: '19px',
    height: '19px',
  },

  '&:hover': {
    '& path': {
      fill: '#053550',
    },
  },

  '&:disabled': {
    cursor: 'default',
    pointerEvents: 'none',
  },
});

const SearchIcon = styled(
  ({ color = '#7D8D96', ...props }: { color?: string }) => (
    <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.65 0C2.98856 0 0 2.98856 0 6.65C0 10.3114 2.98856 13.3 6.65 13.3C8.3106 13.3 9.82776 12.6806 10.9955 11.6672L11.4 12.0717V13.3L17.1 19L19 17.1L13.3 11.4H12.0717L11.6672 10.9955C12.6806 9.82776 13.3 8.3106 13.3 6.65C13.3 2.98856 10.3114 0 6.65 0ZM6.65 1.9C9.2846 1.9 11.4 4.01539 11.4 6.65C11.4 9.2846 9.2846 11.4 6.65 11.4C4.01539 11.4 1.9 9.2846 1.9 6.65C1.9 4.01539 4.01539 1.9 6.65 1.9Z"
        fill={color}
      />
    </svg>
  ),
  { label: 'SearchIcon' },
)(({ theme }) => ({
  position: 'relative',
  display: 'block',

  '& path': {
    transition: 'fill 0.3s',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '5px',
    marginLeft: '4px',
  },
}));

interface Props {
  className?: string;
}

function Search(props: Props) {
  const { className = '' } = props;
  const config = useAppSelector(selectConfig);
  const t = config.texts.website;
  const search = useSearch();

  const options = React.useMemo(
    () =>
      config.people.map((p) => ({
        value: p.id,
        title: `${p.b} ${p.c}${p.displayCityInSearch ? ` (${p.f})` : ''}`,
      })),
    [config.people],
  );

  const onPersonChange = (event: React.SyntheticEvent, value: any) => {
    search.formik.submitForm();
    analytics.gtag.event('search name', { CUSTOM_PARAMETER: value.title || '' });
  };

  return (
    <>
      <form onSubmit={search.formik.handleSubmit}>
        <Container className={cn(className)}>
          <Flex width="100%" alignItems="center">
            <Autocomplete
              noOptionsText={t.main.header.autocomplete.noOptionsText}
              name="filters.person"
              defaultValue={search.formik.values.filters.person}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options}
              onChange={onPersonChange}
              isVirtualList
              getOptionLabel={(option) => (option as (typeof options)[number]).title}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.title}
                </li>
              )}
              renderInput={(params) => (
                <Flex width="100%" alignItems="center">
                  <MuiTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchButton disabled>
                            <SearchIcon />
                          </SearchButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t.main.header.autocomplete.placeholder}
                  />
                </Flex>
              )}
            />
          </Flex>
        </Container>
      </form>
    </>
  );
}

export default Search;
