import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAutocomplete, { AutocompleteChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import MuiPopper from '@mui/material/Popper';
import FormControl from '@mui/material/FormControl';
import { useField } from 'formik';

import Label from './Label';
import { ListboxComponent } from './ListboxComponent';

type StyledAutocompleteProps = AutocompleteProps<string[], boolean, boolean, boolean>;
const Input = styled(MuiAutocomplete, {
  label: 'Input',
})<StyledAutocompleteProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    lineHeight: '19.71px',
    color: '#343A40',
    backgroundColor: '#fff',
    padding: '0 0 0 10px !important',
    minHeight: '51px',
    border: '1px solid #063682',

    '& input': {
      padding: 0,
    },

    '& fieldset': {
      border: 'none',
    },

    '& .MuiAutocomplete-endAdornment': {
      right: 'auto !important',
      left: '0.9rem',
    },

    '& .MuiChip-root': {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      color: '#000',
      backgroundColor: '#F5F5F5',
      border: '1px solid #D9D9D9',
      borderRadius: '0.2rem',
      padding: '0.4rem 0.8rem',
      margin: '0.4rem 0 0.4rem 0.8rem',

      '& .MuiChip-label': {
        padding: 0,
      },

      '& .MuiChip-deleteIcon': {
        color: '#000',
        opacity: 0.45,

        path: {
          transformOrigin: 'center',
          transform: 'scale(0.65)',
        },

        '&:hover': {
          opacity: 1,
        },
      },
    },
  },

  '& .MuiAutocomplete-popupIndicator': {
    color: '#000',
  },
})) as typeof MuiAutocomplete;

const Popper = styled(MuiPopper, { label: 'CustomPopper' })(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: '0',
    borderRadius: '0.5rem',
    backgroundColor: '#fff',

    '& .MuiListSubheader-root': {
      fontSize: '1.2rem',
      lineHeight: '2.2rem',
      color: '#495057',
      backgroundColor: '#DEE2E6',
      padding: '0rem 0.8rem',
      margin: '0 0.8rem',
    },

    '& .Mui-focused': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
      color: '#343A40',
      paddingRight: '1.6rem',

      '& .MuiCheckbox-root': {
        padding: 0,
        margin: '-0.1rem -0.6rem 0 0.8rem',
        backgroundColor: 'transparent',

        '& .MuiSvgIcon-root': {
          color: '#D9D9D9',
        },

        '&.Mui-checked .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },

        '& .MuiTouchRipple-root': {
          display: 'none',
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      '&:hover': {
        backgroundColor: '#E8F2F7 !important',
      },

      '&.multiselect.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&.multiselect.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  isVirtualList?: boolean;
  noOptionsText?: string;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: Props<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { name, label, onChange: externalOnChange, isVirtualList = false, ...autocompleteInputProps } = props;
  const [, meta, helpers] = useField(name);

  const onChange = (event: React.SyntheticEvent, value: any, reason: AutocompleteChangeReason) => {
    helpers.setValue(value);
    externalOnChange?.(event, value, reason);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      {label && <Label>{label}</Label>}
      {isVirtualList ? (
        <Input
          {...autocompleteInputProps}
          PopperComponent={Popper}
          ListboxComponent={ListboxComponent}
          renderOption={(props, option, state) => {
            return [props, option, state] as React.ReactNode;
          }}
          onChange={onChange}
        />
      ) : (
        <Input {...autocompleteInputProps} PopperComponent={Popper} onChange={onChange} />
      )}
    </FormControl>
  );
};

export default Autocomplete;
