import React from 'react';
import { useResponsive } from 'src/hooks/responsive';
import { useAppSelector } from 'src/hooks/store';
import cn from 'classnames';

import Icon from 'src/components/common/Icon';
import { imgixUrl } from 'src/utils/imgix-url';
import { analytics } from 'src/utils/analytics';
import { selectConfig } from 'src/store/config/selectors';

import { Socials } from './Socials';
import { FilterSection } from '../FilterSection';
import { useSearch } from '../FilterSection/Search/Context';

import logoSrc from 'src/assets/topSection/Logo.svg';
import topSectionBgSrc from 'src/assets/topSection/topSectionBg.jpg';
import topSectionBgMobileSrc from 'src/assets/topSection/topSectionBgMobile.jpg';
import topSectionPersonBgMobileSrc from 'src/assets/topSection/mobilePersonBg.jpg';
import imgPlaceholder from 'src/assets/topSection/person-img-placeholder.jpg';

import css from './TopSection.module.scss';

const TopSection = () => {
  const [isMobile] = useResponsive('MOBILE');
  const config = useAppSelector(selectConfig);
  const TEXTS = config.texts.website;
  const search = useSearch();

  const isNoResults = search.result?.success && !search.isFetching ? search.result.data.items.length === 0 : true;
  const personId = search.formik.values.filters.person?.value;
  const personData = personId ? config.people.find((person) => person.id === personId) : undefined;

  const topSectionBg = imgixUrl(topSectionBgSrc);
  const topSectionBgMobile = personId ? topSectionPersonBgMobileSrc : topSectionBgMobileSrc;
  const logo = imgixUrl(logoSrc);

  const bg = isMobile ? topSectionBgMobile : topSectionBg;
  const text = TEXTS.main.topSection.text;

  const handleDetailsBtnClick = () => {
    const formSection = document.querySelector('#form-section');
    if (formSection) formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });

    analytics.gtag.event('add memory from memory page');
  };

  const renderPersonInfo = () => {
    if (!personData) return null;

    return (
      <div className={css.heroDetails}>
        <div className={css.photo} style={{ backgroundImage: `url(${personData.j || imgPlaceholder})` }} />
        <div className={css.details}>
          <Socials className={css.socials} />
          <div className={css.name}>{`${TEXTS.main.topSection.preNameLine} ${[personData.a, personData.b, personData.c]
            .filter(Boolean)
            .join(' ')}`}</div>
          <div className={css.subtitle}>{`${[personData.h, personData.f, personData.k]
            .filter(Boolean)
            .join(', ')}`}</div>
          <div className={css.description}>{personData.l}</div>
          {!isNoResults && (
            <button className={css.toFormBtn} onClick={handleDetailsBtnClick}>
              <Icon type="plus" color="#FFF" />
              <span>{TEXTS.main.header.scrollDownBtn}</span>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={cn(css.topSection, isNoResults && css.noResults)} style={{ backgroundImage: `url('${bg}')` }}>
      <div className={cn(css.info, personId && css.personDetails)}>
        {personId ? (
          renderPersonInfo()
        ) : (
          <>
            <div className={css.hero}>
              {isMobile !== undefined && (
                <div className={css.logoWrap}>
                  <img src={logo} alt="logo" className={css.logo} />
                </div>
              )}
              <div className={css.text} dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
            <FilterSection />
          </>
        )}
      </div>
    </div>
  );
};

export default TopSection;
