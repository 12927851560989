import React from 'react';
import { useLocation } from 'react-router';

import { DOMAIN_URL } from 'src/constants/other';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';

import Cooladata from 'src/components/common/Cooladata';
import { Header } from 'src/components/common/Header';
import { Footer } from 'src/components/common/Footer';
import Head from 'src/components/common/Head';

import SearchContextProvider from './FilterSection/Search/Context';
import { useSearch } from './FilterSection/Search/Context';
import { FormSection } from './FormSection';
import { List } from './List';
import TopSection from './TopSection';

import css from './Main.module.scss';

const Main = () => {
  const location = useLocation();
  const search = useSearch();
  const { seo, og } = useAppSelector(selectConfig).seo_og;

  const page = search.formik.values.page;
  const seoTitle = page > 1 ? `${seo.mainPage.title} - עמוד ${page}` : seo.mainPage.title;
  const seoDescription = page > 1 ? `${seo.mainPage.description} - עמוד ${page} ` : seo.mainPage.description;

  return (
    <>
      <Head seo={{ title: seoTitle, description: seoDescription }} og={og.mainPage}>
        <link rel="canonical" href={`${DOMAIN_URL}${location.pathname}${location.search}`}></link>
        {page > 1 ? <meta property="fb:app_id" content={`${page}`} /> : <meta property="fb:app_id" content="main" />}
      </Head>
      <div className={css.main} id="top">
        <Header />
        <TopSection />
        <List />
        <FormSection />
        <Footer />
      </div>
      <Cooladata pageId="HP" />
    </>
  );
};

export default () => {
  return (
    <SearchContextProvider>
      <Main />
    </SearchContextProvider>
  );
};
