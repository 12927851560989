export const analytics = {
  gtag: {
    event: (action: string, params?: Record<string, string>) => {
      // @ts-expect-error gtag is added in html.head:
      const { gtag } = window;
      if (process.env.ENV === 'local') {
        console.log('gtag:local', action, params);
        return;
      }

      if (gtag) {
        console.log('gtag:', action, params);
        gtag('event', action, params);
      } else {
        console.log('%cgtag is undefined', 'color: red');
      }
    },
  },
};
