import React from 'react';
import { Helmet } from 'react-helmet-async';

import shareImg from 'src/assets/share-img.jpg';
import faviconUrl from 'src/assets/favicon/favicon.png';
import { imgixUrl } from 'src/utils/imgix-url';

interface Props {
  seo?: {
    title?: string;
    description?: string;
  };
  og?: {
    title?: string;
    description?: string;
    imageUrl?: string;
  };
  children?: React.ReactNode;
}

const imgixParams = { 'max-w': 1200, h: 630, q: 80, auto: 'format' };

const Head = (props: Props) => {
  const { seo, og } = props;
  const domain = !!(typeof window !== 'undefined' && window.document) ? window.location.href : '';
  const SEO = { title: 'Mako N12 7-10 Memory Wall Website', description: '', ...seo };
  const OG = {
    title: 'Mako N12 7-10 Memory Wall Website',
    description: '',
    ...og,
    imageUrl: imgixUrl(og?.imageUrl, imgixParams) ?? shareImg,
  };

  return (
    <Helmet>
      <title>{SEO.title}</title>
      <meta name="description" content={SEO.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={domain} />
      <meta property="og:site_name" content="N12" />
      <meta property="og:locale" content="he_IL" />
      <meta property="og:title" content={OG.title} />
      <meta property="og:description" content={OG.description} />
      <meta property="og:image" content={OG.imageUrl} />
      <link rel="icon" type="image/png" href={faviconUrl} />
      {props.children}
    </Helmet>
  );
};

export default Head;
