import { SHARE_CONSTANTS } from 'src/constants/other';
import { ObjectValues } from 'src/types/object-values';

const CAN_USE_DOM = typeof window !== 'undefined';

export type ShareType = ObjectValues<typeof SHARE_CONSTANTS>;

type params = {
  type: ShareType;
  ogTitle?: string;
  ogDescription?: string;
};

export const share = ({ type, ogTitle = '', ogDescription = '' }: params) => {
  if (!CAN_USE_DOM) {
    return;
  }

  const domain = ogTitle ? window.location.href : window.location.origin;
  const titleProp = document.querySelector('meta[property="og:title"]');
  const title = ogTitle || (titleProp ? titleProp.getAttribute('content') : '');
  const descriptionProp = document.querySelector('meta[property="og:description"]');
  const description = ogDescription || (descriptionProp ? descriptionProp.getAttribute('content') : '');
  const isMobile = !!window.ontouchstart && window.matchMedia('(max-width: 1024px)').matches;
  const encode = encodeURIComponent;

  if (type === 'Facebook') {
    const fbURL = 'https://www.facebook.com/sharer.php?u=';

    window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
  }

  if (type === 'Twitter') {
    const twURL = 'https://twitter.com/intent/tweet?url=';
    const twText = `${title}\n${description}`;
    const finalURL = `${twURL}${domain}&text=${encode(`${twText}`)}`;

    const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
    if (shareWindow) shareWindow.opener = null;
  }

  if (type === 'whatsApp') {
    const text = `${title}\n${description}\n${domain}`;
    const waShareLink = `https://wa.me/?text=${encode(`${text}`)}`;
    if (!isMobile) {
      window.open(waShareLink, '_blank');
    } else {
      window.location.href = waShareLink;
    }
  }

  if (type === 'E-mail') {
    if (title && domain) {
      window.location.href =
        'mailto:?subject=' +
        encode(title) +
        '&body=' +
        encode(title) +
        '%0D%0A' +
        encode(description + ' ') +
        '%0D%0A%0D%0A' +
        encode(domain);
    }
  }
};
