/**
 * helper for getting key from image url
 * (work only with next view of url: https://storage.googleapis.com/mako-mymemoryn12.appspot.com/uploads%2F1686554869239.png)
 *
 * @param imgSrc string
 * @returns string
 */
export const getKeyFromImageUrl = (imgSrc: string) => {
  const croppedImgKey = imgSrc.split('%2F')[1] || '12345.png';

  return parseInt(croppedImgKey, 10);
};
