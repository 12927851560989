import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface Props {
  className?: string;
  direction?: React.CSSProperties['flexDirection'];
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  flexWrap?: React.CSSProperties['flexWrap'];
  gap?: React.CSSProperties['gap'];
}

const Flex = styled(Box, { label: 'Flex' })<Props>((props) => ({
  display: 'flex',
  flexDirection: props.direction || 'row',
  justifyContent: props.justifyContent || 'flex-start',
  alignItems: props.alignItems || 'flex-start',
  flexWrap: props.flexWrap || 'nowrap',
  gap: props.gap || '0',
}));

export default Flex;
