import { getKeyFromImageUrl } from './get-key-from-image-url';

/**
 * helper for getting key from image url
 * (work only with next view of url: https://storage.googleapis.com/mako-mymemoryn12.appspot.com/uploads%2F1686554869239.png)
 *
 * @param imgSrc string
 * @returns string
 */
export const getCroppedPhoto = (
  imgSrc: string | undefined,
  target: 'photo' | 'mainPhoto',
  metadata?: {
    photo?: Record<string, string>;
    mainPhoto?: Record<string, string>;
  },
) => {
  if (!imgSrc) {
    return undefined;
  }

  const key = getKeyFromImageUrl(imgSrc);

  return metadata?.[target]?.[key];
};
