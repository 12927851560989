import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import 'src/styles/index.scss';
import { theme } from 'src/theme';
import Head from 'src/components/common/Head';

const PageLayout = () => {
  const removePreloaderTimeoutRef = React.useRef<NodeJS.Timeout | number | null>(null);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);

      if (removePreloaderTimeoutRef.current) clearTimeout(removePreloaderTimeoutRef.current);
      removePreloaderTimeoutRef.current = setTimeout(() => {
        if (document && document.body) {
          document.body.removeChild(preloadingScreen);
        }
      }, 1500);
    }
  };

  React.useEffect(() => {
    removeRootPreloader();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Head />
        <Outlet />
      </ThemeProvider>
    </>
  );
};

export default PageLayout;
