import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetStory } from 'src/hooks/get-story';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { selectImagePopupState } from 'src/store/ui/selectors';
import { TAGS_REG_EXP } from 'src/constants/other';
import { Header } from 'src/components/common/Header';
import { Footer } from 'src/components/common/Footer';

import Head from 'src/components/common/Head';

import TopSection from './TopSection';
import SubmissonWrapper from './SubmissionWrapper';
import ImagePopup from '../../common/ImagePopup';

import css from './Memory.module.scss';

export default function Memory() {
  const config = useAppSelector(selectConfig);
  const params = useParams<{ personId: string; memoryId: string }>();
  const [memoryData, isFetching] = useGetStory({ id: params.memoryId });
  const personData = config.people.find((person) => person.id === params.personId);
  const victimName = personData ? `${personData?.b} ${personData?.c}` : '';
  const senderName = memoryData ? memoryData.contact.name : '';

  const imageUrl = useAppSelector(selectImagePopupState);
  const { seo, og } = useAppSelector(selectConfig).seo_og;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head
        seo={{
          title: `ההנצחה של ${senderName} לזכר ${victimName} ז"ל`,
          description:
            memoryData?.memory?.story
              ?.replace(/<\/p>/gi, ' ')
              .replace(TAGS_REG_EXP, '')
              .replace(/\&quot;/gi, '"') || '',
        }}
        og={{
          title: `ההנצחה של ${senderName} לזכר ${victimName} ז"ל`,
          description:
            memoryData?.memory?.story
              ?.replace(/<\/p>/gi, ' ')
              .replace(TAGS_REG_EXP, '')
              .replace(/\&quot;/gi, '"') || '',
          imageUrl: og.mainPage.imageUrl,
        }}
      ></Head>
      <div className={css.memoryWrapper}>
        <Header />
        <TopSection />
        <SubmissonWrapper />
        <Footer />
        <ImagePopup src={imageUrl} />
      </div>
    </>
  );
}
