import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Icon from 'src/components/common/Icon';
import { useAppSelector } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';
import { selectConfig } from 'src/store/config/selectors';
import { useSearch } from 'src/components/pages/Main/FilterSection/Search/Context';

import { imgixUrl } from 'src/utils/imgix-url';
import { analytics } from 'src/utils/analytics';

import headerLogoSrc from 'src/assets/header/headerLogo.svg';
import nLogoSrc from 'src/assets/header/n12-header-logo.svg';

import css from './Header.module.scss';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className = '' }) => {
  const [isBackground, setIsBackground] = useState<boolean>(false);

  const [isMobile] = useResponsive('MOBILE');
  const [isSmallMobile] = useResponsive('SMALL_MOBILE');
  const search = useSearch();
  const TEXTS = useAppSelector(selectConfig).texts.website;

  const personId = search.formik.values.filters.person?.value;

  const isCentralLogoVisible = personId ? !isSmallMobile : !isMobile;
  const isBackBtnVisible = Boolean(personId) && isSmallMobile;
  const isNewStoryBtnVisible = personId ? !isSmallMobile : true;

  const nLogo = imgixUrl(nLogoSrc);
  const headerLogo = imgixUrl(headerLogoSrc);

  const onScrollDownClick = () => {
    const formSection = document.querySelector('#form-section');
    if (formSection) formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });

    analytics.gtag.event('header leave memory');
  };

  const onScrollUpClick = () => {
    const top = document.querySelector('#top');
    if (top) top.scrollIntoView({ behavior: 'smooth', block: 'start' });

    analytics.gtag.event('header logo');
  };

  useEffect(() => {
    const controller = new AbortController();
    const handler = (e: Event) => {
      const target = e.currentTarget as Window;

      if (target.scrollY > 10 && !isBackground) {
        setIsBackground(true);
      } else if (target.scrollY <= 10 && isBackground) {
        setIsBackground(false);
      }
    };

    window.addEventListener('scroll', handler, { signal: controller.signal });
    return () => controller.abort();
  }, [isBackground]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [personId]);

  return (
    <header className={cn(css.header, className)}>
      <a
        className={cn(css.n12LogoWrap)}
        href="https://n12.co.il/"
        target="_blank"
        rel="noreferrer"
        onClick={() => analytics.gtag.event('send to N12')}
      >
        <img src={nLogo} alt="N12" />
      </a>
      {isCentralLogoVisible && (
        <h1 className={cn(css.logoWrapper, (isBackground || personId) && css.show)}>
          <Link to="..">
            <img
              src={headerLogo}
              alt="הזיכרון שלי מלחמת יום הכיפורים"
              title="הזיכרון שלי מלחמת יום הכיפורים"
              onClick={onScrollUpClick}
              className={css.innerLogo}
            />
          </Link>
        </h1>
      )}

      <div className={css.left}>
        {isNewStoryBtnVisible && (
          <button onClick={onScrollDownClick}>
            <Icon type="plus" color="#0E151D" />
            <span className={css.text}>{TEXTS.main.header.scrollDownBtn}</span>
          </button>
        )}

        {isBackBtnVisible && (
          <Link to=".." className={css.back} dangerouslySetInnerHTML={{ __html: TEXTS.main.header.backBtn }} />
        )}
      </div>
      <div />
    </header>
  );
};
