import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Label = styled((props) => <Typography {...props} component="label" />, { label: 'Label' })(() => ({
  fontSize: '16px',
  lineHeight: '17.52px',
  marginBottom: '4px',
  color: '#FFF',
}));

export default Label;
