import React, { useEffect, useState } from 'react';

import Search from './Search';

import css from './FilterSection.module.scss';

export const FilterSection = () => {
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const onPageLoad = () => {
      if (document.readyState === 'complete') {
        setIsPageLoaded(true);
      }
    };

    if (document.readyState === 'complete') {
      setIsPageLoaded(true);
    }

    window.addEventListener('load', onPageLoad, false);
    return () => window.removeEventListener('load', onPageLoad);
  }, [isPageLoaded]);

  return (
    <div className={css.filterSection}>
      <div className={css.content}>
        <Search />
      </div>
    </div>
  );
};
