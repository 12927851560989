import qs from 'qs';
import { IMGIX_URL } from 'src/constants/other';

export function imgixUrl(url?: string, params?: Record<string, string | number>) {
  if (!url) return url;

  if (process.env.ENV !== 'production') {
    return url;
  }

  const [src, query] = url.split('?');
  const queryWithParams = { ...qs.parse(query, { ignoreQueryPrefix: true }), ...params };
  const queryStr = qs.stringify(queryWithParams);
  const imgixSrc = src.replace(`https://storage.googleapis.com/${process.env.PROJECT_ID}.appspot.com`, IMGIX_URL);

  return `${imgixSrc}${queryStr ? '?' + queryStr : ''}`;
}
