import React, { FC } from 'react';
import cn from 'classnames';

import { analytics } from 'src/utils/analytics';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { ImageCredit } from 'src/components/common/ImageCredit';

import { useSearch } from '../../FilterSection/Search/Context';

import css from './ListItem.module.scss';

type Props = {
  id: string;
  isPersonPage?: boolean;
  title: string;
  personId: string;
  personImg: string;
  imgUrl?: string;
  subtitle?: string;
  imageCredit: string;
  sender?: string;
};

export const ListItem: FC<Props> = (props) => {
  const { title, imgUrl, imageCredit, subtitle, personId, personImg, isPersonPage = false, sender } = props;
  const TEXTS = useAppSelector(selectConfig).texts.website.main;
  const search = useSearch();

  const onItemClick = () => {
    search.formik.setFieldValue('filters.person', { value: personId, title });
    search.formik.setFieldValue('page', 1);
    search.formik.submitForm();

    analytics.gtag.event('direct click name or photo', { CUSTOM_PARAMETER: title });
  };

  return (
    <div className={css.listItem}>
      {!isPersonPage && (
        <div className={css.header}>
          <img className={css.personImg} onClick={onItemClick} src={personImg} />
          <div className={css.details}>
            <div className={css.personName} onClick={onItemClick}>
              {title}
            </div>
            <div className={css.sender}>
              {TEXTS.list.listItem.sender}: {sender}
            </div>
          </div>
        </div>
      )}
      <div className={cn(css.photo, !isPersonPage && css.link)} onClick={isPersonPage ? undefined : onItemClick}>
        <img style={process.env.ENV !== 'production' ? { objectFit: 'cover' } : undefined} src={imgUrl} alt={title} />
        {imageCredit && <ImageCredit textAlign="right" className={css['image-credit']} text={imageCredit} />}
      </div>
      {isPersonPage && (
        <div className={css.senderInfo}>
          {TEXTS.list.listItem.sender}: {sender}
        </div>
      )}
      <div className={css.subtitle}>
        <span dangerouslySetInnerHTML={{ __html: subtitle || '' }} />
      </div>
    </div>
  );
};
