import React from 'react';
import cn from 'classnames';

import css from './ImageCredit.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  textAlign?: 'right' | 'left';
  text: string;
}

export const ImageCredit = (props: Props) => {
  const { className, textAlign = 'left', text, ...cssProps } = props;
  return (
    <div className={cn(css.imageCredit, css[textAlign], className)} {...cssProps}>
      {text}
    </div>
  );
};
