import React from 'react';
import { Pagination } from '@mui/material';
import cn from 'classnames';

import { getKeyFromImageUrl } from 'src/utils/get-key-from-image-url';
import { getCroppedPhoto } from 'src/utils/get-cropped-photo';
import { selectConfig } from 'src/store/config/selectors';
import { useResponsive } from 'src/hooks/responsive';
import { useAppSelector } from 'src/hooks/store';
import { analytics } from 'src/utils/analytics';
import { imgixUrl } from 'src/utils/imgix-url';

import { useSearch, PAGE_SIZE, MOBILE_PAGE_SIZE } from '../FilterSection/Search/Context';
import { LoaderItem } from './Loader';
import { ListItem } from './ListItem';

import imgPlaceholder from 'src/assets/topSection/person-img-placeholder.jpg';

import css from './List.module.scss';

export const List = () => {
  const search = useSearch();
  const config = useAppSelector(selectConfig);
  const TEXTS = config.texts.website;
  const items = search.result?.success ? search.result.data.items : [];
  const [isMobile] = useResponsive('MOBILE');
  const listRef = React.useRef<HTMLDivElement | null>(null);

  const totalItems = search.result?.success ? search.result.data.page.total : 0;
  const totalPages = Math.ceil(totalItems / (isMobile ? MOBILE_PAGE_SIZE : PAGE_SIZE));
  const isPersonPage = Boolean(search.formik.values.filters.person?.value);

  const onScrollClick = () => {
    if (listRef.current) {
      window.scrollTo({
        top: listRef.current?.offsetTop - 58, //58 - header height
        behavior: 'auto',
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<unknown>, value: number) => {
    search.formik.setFieldValue('page', value);
    search.formik.submitForm();
    onScrollClick();
  };

  const onAddStoryBtnClick = () => {
    const formSection = document.querySelector('#form-section');
    if (formSection) formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });

    analytics.gtag.event('add memory from no memory page');
  };

  const renderContent = () => {
    if (items.length === 0) {
      return (
        <div className={css.emptyItem}>
          <button onClick={onAddStoryBtnClick}>
            <span>+</span>
            <span>{TEXTS.main.list.emptyItem.buttonLabel}</span>
          </button>
          <div>
            <div className={css.row} />
            <div className={css.row} />
            <div className={css.row} />
          </div>
        </div>
      );
    }

    return items.map((item) => {
      const personData = config.people.find((person) => person.id === item.personId.toString());
      const personName = personData ? `${personData.b} ${personData.c}` : '';
      const personImage = personData ? personData.j || imgPlaceholder : imgPlaceholder;
      const cropped = item.memory?.photo
        ? getCroppedPhoto(item.memory?.photo, 'mainPhoto', item.memory?.metadata)
        : item.memory?.photos?.[0]?.url;
      const imageCredit =
        item.memory?.metadata?.imageCredit?.[
          getKeyFromImageUrl(
            item.memory?.photo || item.memory?.photos?.[0]?.url || item.memory?.photosUploadedByAdmin?.[0]?.url || '',
          )
        ] ?? '';

      const imgixParams = {
        w: 330,
        h: 207,
        auto: 'format,compress',
        ...(!item.disableFaceRecognition && { fit: 'facearea,crop', facepad: 100 }),
      };

      let imgUrl = imgixUrl(cropped || item.memory?.photo || item.memory?.photos?.[0]?.url, imgixParams);

      if (item.memory?.photos) {
        if (item.memory?.photo && item.memory.metadata?.hidePhoto) {
          const hide = item.memory.metadata?.hidePhoto.find((photo) => photo === item.memory?.photo);

          if (hide) imgUrl = '';
        }
      }

      return (
        <ListItem
          id={item._id.toString()}
          title={personName}
          isPersonPage={isPersonPage}
          personId={item.personId.toString()}
          personImg={personImage}
          subtitle={item.memory?.story}
          imgUrl={imgUrl}
          imageCredit={imageCredit}
          key={item._id.toString()}
          sender={item.contact?.name || ''}
        />
      );
    });
  };

  return (
    <div className={css.listWrapper} ref={listRef}>
      <div className={cn(css.list, items.length === 0 && css.noResults)}>
        {search.isFetching ? (
          <>
            <LoaderItem />
            <LoaderItem />
            <LoaderItem />
            <LoaderItem />
          </>
        ) : (
          <>{renderContent()}</>
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={search.formik.values.page}
          onChange={handleChange}
          className={css.pageSwitcher}
          shape="rounded"
          color="primary"
          size="small"
        />
      )}
    </div>
  );
};
