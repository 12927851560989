import cn from 'classnames';
import React from 'react';

import { share, ShareType } from 'src/utils/share';
import { analytics } from 'src/utils/analytics';

import Icon from 'src/components/common/Icon';
import { SHARE_CONSTANTS } from 'src/constants/other';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';

import css from './Socials.module.scss';

interface Props {
  className?: string;
}

export const Socials: React.FC<Props> = (props) => {
  const { className = '' } = props;

  const { og } = useAppSelector(selectConfig).seo_og;

  const clickOnShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id as ShareType;

    if (!id) return;

    if (id === 'Facebook' || id === 'Twitter' || id === 'whatsApp') {
      share({ type: id, ogTitle: og.mainPage.title, ogDescription: og.mainPage.description });
    }

    analytics.gtag.event('share memory page', {
      CUSTOM_PARAMETER: `${id?.toLowerCase().replaceAll('-', '')}`,
    });
  };

  return (
    <div className={cn(css.socials, className)}>
      <button data-id={SHARE_CONSTANTS.WHATS_APP} onClick={clickOnShare}>
        <Icon type="inner-whatsapp" />
      </button>
      <button data-id={SHARE_CONSTANTS.TWITTER} onClick={clickOnShare}>
        <Icon type="inner-twitter" />
      </button>
      <button data-id={SHARE_CONSTANTS.FACEBOOK} onClick={clickOnShare}>
        <Icon type="inner-facebook" />
      </button>
    </div>
  );
};
