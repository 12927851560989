import React from 'react';
import cn from 'classnames';

import { share, ShareType } from 'src/utils/share';
import { analytics } from 'src/utils/analytics';
import { SHARE_CONSTANTS } from 'src/constants/other';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { imgixUrl } from 'src/utils/imgix-url';

import logoSrc from 'src/assets/footer/n12-logo-dark.svg';
import { useResponsive } from 'src/hooks/responsive';
import Icon from 'src/components/common/Icon';

import css from './Footer.module.scss';

interface Props {
  className?: string;
}

export const Footer = (props: Props) => {
  const TEXTS = useAppSelector(selectConfig).texts.website;
  const [isMobile] = useResponsive('MOBILE');
  const logoBlue = imgixUrl(logoSrc);
  const link =
    '<a href="https://undusting.org/wallsOf710/s/remembering/page/stickers" target="_blank">https://undusting.org/wallsOf710/s/remembering/page/stickers</a>';

  const clickOnShare = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const type = e.currentTarget.dataset.id as ShareType;

    if (type === 'Facebook' || type === 'Twitter' || type === 'whatsApp') {
      share({ type });
    }

    analytics.gtag.event('footer share', {
      CUSTOM_PARAMETER: `${type?.toLowerCase().replaceAll('-', '')}`,
    });
  };

  return (
    <footer className={cn(css.footer, props.className)}>
      <div className={css.content}>
        <div className={css.logos}>
          <img src={logoBlue} alt="N12" />
        </div>
        <div className={css.text}>
          <div className={css.title}>{TEXTS.main.footer.texts.title}</div>
          <div className={css.top}>
            <span dangerouslySetInnerHTML={{ __html: TEXTS.main.footer.texts.top }}></span>
          </div>
          <div className={css.bottom}>
            <span
              dangerouslySetInnerHTML={{
                __html: isMobile
                  ? TEXTS.main.footer.texts.bottomMobile.replace('{{link}}', link)
                  : TEXTS.main.footer.texts.bottom.replace('{{link}}', link),
              }}
            ></span>
          </div>
        </div>
        <div className={css.socials}>
          <button className={css.socialBtn} data-id={SHARE_CONSTANTS.WHATS_APP} onClick={clickOnShare}>
            <Icon type="inner-whatsapp" />
          </button>
          <button className={css.socialBtn} data-id={SHARE_CONSTANTS.TWITTER} onClick={clickOnShare}>
            <Icon type="inner-twitter" />
          </button>
          <button className={css.socialBtn} data-id={SHARE_CONSTANTS.FACEBOOK} onClick={clickOnShare}>
            <Icon type="inner-facebook" />
          </button>
        </div>
        <div className={css.rabbiCredit}>
          Developed by&nbsp;
          <a href="https://www.rabbi.co.il" rel="noreferrer" title="רבי אינטראקטיב" target="_blank">
            Rabbi
          </a>
        </div>
      </div>
    </footer>
  );
};
